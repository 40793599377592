import * as moment from 'moment';


export class ActivatedVoucherSnackListResponse {
  idTicket: number | undefined;
  airlineCompanyName: string | undefined;
  flightNumber: string | undefined;
  ticketOpeningDate: moment.Moment | undefined;
  totalVoucherSnack: number | undefined;
  totalAmount: number | undefined;
  vouchersSnacks: VoucherSnack[] | undefined;
}

export class ActivatedVoucherSnackReadQueryResponse {
  items: ActivatedVoucherSnackListResponse[] | undefined;
}

export class ActivationError {
  voucher: string | undefined;
  seq: string | undefined;
  errorMessage: string | undefined;
  exception: string | undefined;
}

export class AirlineCompany {
  idAirlineCompany: number | undefined;
  name: string | undefined;
  iataCode: string | undefined;
}

export class AirlineCompanyCreateCommand {
  name: string | undefined;
  iataCode: string | undefined;
}

export class AirlineCompanyListQueryResponse {
  items: AirlineCompany[] | undefined;
}

export class AirlineCompanyUpdateCommand {
  idAirlineCompany: number | undefined;
  name: string | undefined;
  iataCode: string | undefined;
}

export class Airport {
  idAirport: number | undefined;
  name: string | undefined;
  iataCode: string | undefined;
  cityCode: string | undefined;
  cityName: string | undefined;
  countryName: string | undefined;
  countryCode: string | undefined;
  timezone: string | undefined;
  location: GeolocationPosition | undefined;
}

export class AirportHasHotel {
  idAirportHasHotel: number | undefined;
  idAirport: number | undefined;
  idHotel: number | undefined;
  rating: number | undefined;
  googleMapDistance: string | undefined;
  googleMapTimeDistance: string | undefined;
  airport: Airport | undefined;
  hotel: HotelSummary | undefined;
}

export class AirportHasHotelCreateCommand {
  idAirport: number | undefined;
  idHotel: number | undefined;
  rating: number | undefined;
  googleRoutesDistanceInMeters: number | undefined;
  googleRoutesDistanceTime: string | undefined;
  googleRoutesEncodedPolyline: string | undefined;
  googleRoutesError: string | undefined;
}

export class AirportHasHotelDeleteCommand {
  idAirportHasHotel: number | undefined;
}

export class AirportHasHotelListAirportByHotelQueryResponse {
  items: AirportHasHotel[] | undefined;
}

export class AirportHasHotelUpdateCommand {
  idAirportHasHotel: number | undefined;
  idAirport: number | undefined;
  idHotel: number | undefined;
  rating: number | undefined;
  googleRoutesDistanceInMeters: number | undefined;
  googleRoutesDistanceTime: string | undefined;
  googleRoutesEncodedPolyline: string | undefined;
  googleRoutesError: string | undefined;
}

export class AirportListQueryResponse {
  items: Airport[] | undefined;
}

export class AirportResponse {
  idAirport: number | undefined;
  name: string | undefined;
  iataCode: string | undefined;
  countryName: string | undefined;
  cityName: string | undefined;
  timezone: string | undefined;
  extendedName: string | undefined;
  notes: string | undefined;
  latitude: number | undefined;
  longitude: number | undefined;
}

export class ApplicationException {
  message: string | undefined;
  stackTrace: string | undefined;
}

export class Attachment {
  idAttachment: number | undefined;
  fileName: string | undefined;
  contentType: string | undefined;
  fileUrl: string | undefined;
}

export class AuthRefreshTokenCommand {
  username: string | undefined;
  refreshToken: string | undefined;
  ipAddress: string | undefined;
}

export class AuthTokenByIdAuthJwtTokenCommand {
  idAuthJwtToken: string | undefined;
  ipAddress: string | undefined;
}

export class AuthTokenCommand {
  username: string | undefined;
  password: string | undefined;
  ipAddress: string | undefined;
}

export class AuthTokenHotacDailyRemindCommand {
  idHotel: number | undefined;
  username: string | undefined;
  date: moment.Moment | undefined;
  ipAddress: string | undefined;
}

export class AuthTokenHotacReservationDetailRemindCommand {
  idHotel: number | undefined;
  username: string | undefined;
  idServiceAirportToHotel: number | undefined;
  ipAddress: string | undefined;
}

export class AuthenticationToken {
  idAuthJwtToken: string | undefined;
  idUser: number | undefined;
  username: string | undefined;
  accessToken: string | undefined;
  expiresIn: number | undefined;
  refreshToken: string | undefined;
  refreshTokenExpiresIn: number | undefined;
}

export class BedBankAirportListQuery {
  date: moment.Moment | undefined;
  airport: string | undefined;
}

export class BedBankAirportListQueryResponse {
  items: BedBankAirportResponse[] | undefined;
}

export class BedBankAirportResponse {
  idAirport: number | undefined;
  airport: string | undefined;
  iataCode: string | undefined;
  updateAt: moment.Moment | undefined;
  bedBankAirportRoomsTodayMinusOne: BedBankAirportRoom | undefined;
  bedBankAirportRoomsToday: BedBankAirportRoom | undefined;
  bedBankAirportRoomsTodayPlusOne: BedBankAirportRoom | undefined;
  bedBankAirportRoomsTodayPlusTwo: BedBankAirportRoom | undefined;
}

export class BedBankAirportRoom {
  singleRooms: number | undefined;
  doubleRooms: number | undefined;
  tripleRooms: number | undefined;
  hotels: number | undefined;
}

export class BedBankHotel {
  hotel: HotelSummary | undefined;
  updateAt: moment.Moment | undefined;
  bedBankHotelRoomsTodayMinusOne: BedBankHotelRoom | undefined;
  bedBankHotelRoomsToday: BedBankHotelRoom | undefined;
  bedBankHotelRoomsTodayPlusOne: BedBankHotelRoom | undefined;
  bedBankHotelRoomsTodayPlusTwo: BedBankHotelRoom | undefined;
}

export class BedBankHotelListQuery {
  date: moment.Moment | undefined;
  idAirport: number | undefined;
  hotel: string | undefined;
}

export class BedBankHotelListQueryResponse {
  items: BedBankHotel[] | undefined;
}

export class BedBankHotelRoom {
  singleRooms: number | undefined;
  doubleRooms: number | undefined;
  doubleRates: number | undefined;
  tripleRooms: number | undefined;
}

export class BuySellSummary {
  totalBuyFoodsAmount: number | undefined;
  totalBuyRoomsAmount: number | undefined;
  totalBuyAmount: number | undefined;
  totalBuyTaxableAmount: number | undefined;
  totalSellFoodsAmount: number | undefined;
  totalSellRoomsAmount: number | undefined;
  totalSellTaxableAmount: number | undefined;
  totalSellAmount: number | undefined;
  totalCityTaxAmount: number | undefined;
}

export class Contract {
  idContract: number | undefined;
  idHotel: number | undefined;
  idContractType: ContractTypeEnum | undefined;
  idContractState: ContractStateEnum | undefined;
}

export class ContractPlanRateReadQueryResponse {
  idContract: number | undefined;
  idContractPlanRate: number | undefined;
  idContractType: ContractTypeEnum | undefined;
  bestAvailableRateSalePercentage: number | undefined;
  hotelRoomGroups: HotelRoomGroup[] | undefined;
}

export class ContractServiceType {
  idContractServiceType: ContractServiceTypeEnum | undefined;
  name: string | undefined;
}

export enum ContractServiceTypeEnum {
  RESTAURANT = 1,
  PET = 2,
  EARLY_CHECKIN = 3,
  LATE_CHECKOUT = 4,
}

export class ContractState {
  idContractState: ContractStateEnum | undefined;
  name: string | undefined;
}

export enum ContractStateEnum {
  ACTIVE = 1,
  INACTIVE = 2,
  EXPIRED = 3,
}

export class ContractType {
  idContractType: ContractTypeEnum | undefined;
  name: string | undefined;
}

export enum ContractTypeEnum {
  NEGOTIATED_RATES = 1,
  ON_REQUEST_RATES = 2,
  NOT_NEGOTIATED = 3,
}

export class Country {
  idCountry: number | undefined;
  code: string | undefined;
  name: string | undefined;
  search: string | undefined;
}

export class CountryListQueryResponse {
  items: Country[] | undefined;
}

export class Customer {
  idCustomer: number | undefined;
  name: string | undefined;
}

export class CustomerAirlineCompany {
  idCustomerAirlineCompany: number | undefined;
  idCustomer: number | undefined;
  name: string | undefined;
  idPassengerRegistrationTypeDefault: PassengerRegistrationTypeEnum | undefined;
  passengerRegistrationTypeDefaultName: string | undefined;
  idVoucherSnackManagementTypeDefault: VoucherSnackManagementTypeEnum | undefined;
  voucherSnackManagementTypeDefaultName: string | undefined;
  passengerGroupListUploadTypes: PassengerGroupListUploadType[] | undefined;
  needsTransport: boolean | undefined;
}

export class CustomerAirlineCompanyCreateCommand {
  idCustomer: number | undefined;
  idVoucherSnackManagementTypeDefault: VoucherSnackManagementTypeEnum | undefined;
  idPassengerRegistrationTypeDefault: PassengerRegistrationTypeEnum | undefined;
  needsTransport: boolean | undefined;
  idsPassengerGroupListUploadType: PassengerGroupListUploadTypeEnum[] | undefined;
}

export class CustomerAirlineCompanyInAirport {
  idAirport: number | undefined;
  name: string | undefined;
  iataCode: string | undefined;
  cityCode: string | undefined;
  cityName: string | undefined;
  countryName: string | undefined;
  countryCode: string | undefined;
  timezone: string | undefined;
  location: GeolocationPosition | undefined;
  idCustomerAirlineCompanyInAirport: number | undefined;
  idCustomerAirlineCompany: number | undefined;
}

export class CustomerAirlineCompanyInAirportCreateCommand {
  idCustomerAirlineCompany: number | undefined;
  idAirport: number | undefined;
}

export class CustomerAirlineCompanyInAirportListQueryResponse {
  items: CustomerAirlineCompanyInAirport[] | undefined;
}

export class CustomerAirlineCompanyListByCurrentUserQueryResponse {
  items: CustomerAirlineCompany[] | undefined;
}

export class CustomerAirlineCompanyListQueryResponse {
  items: CustomerAirlineCompany[] | undefined;
}

export class CustomerAirlineCompanyListUsersQueryResponse {
  items: User[] | undefined;
}

export class CustomerAirlineCompanyUpdateCommand {
  idCustomerAirlineCompany: number | undefined;
  idVoucherSnackManagementTypeDefault: VoucherSnackManagementTypeEnum | undefined;
  idPassengerRegistrationTypeDefault: PassengerRegistrationTypeEnum | undefined;
  needsTransport: boolean | undefined;
  idsPassengerGroupListUploadType: PassengerGroupListUploadTypeEnum[] | undefined;
}

export class CustomerAirlineCompanyUploadTypeListResponse {
  items: PassengerGroupListUploadTypeResponse[] | undefined;
}

export enum CustomerContractTypeEnum {
  PERCENTAGE_RATE = 1,
  FLAT_RATE = 2,
}

export class CustomerListQueryResponse {
  items: Customer[] | undefined;
}

export enum EntityEnum {
  SUPPLIER_TRANSPORT_COMPANY = 1,
  SUPPLIER_HOTEL = 2,
  SUPPLIER_FOOD_SERVICE_COMPANY = 3,
  CUSTOMER_AIRLINE_COMPANY = 4,
}

export class EnumListQueryResponse {
  contractStates: ContractState[] | undefined;
  contractTypes: ContractType[] | undefined;
  contractServiceTypes: ContractServiceType[] | undefined;
  hotelRoomExclusiveServices: HotelRoomExclusiveService[] | undefined;
  hotelRoomInformationSources: HotelRoomInformationSource[] | undefined;
  hotelRoomOccupancies: HotelRoomOccupancy[] | undefined;
  hotelRoomResultTypes: HotelRoomResultType[] | undefined;
  hotelRoomTypes: HotelRoomType[] | undefined;
  passengerGroupRoles: PassengerGroupRole[] | undefined;
  passengerGroupListUploadTypes: PassengerGroupListUploadType[] | undefined;
  passengerRegistrationTypes: PassengerRegistrationType[] | undefined;
  serviceRequestStates: ServiceRequestState[] | undefined;
  serviceRequestTypes: ServiceRequestType[] | undefined;
  userStates: UserState[] | undefined;
  userTypes: UserType[] | undefined;
  voucherSnackManagementTypeEnum: VoucherSnackManagementType[] | undefined;
  transportOrderStatusTypeEnum: TransportOrderStatusType[] | undefined;
  roomSurveyWorklistStateEnum: RoomSurveyWorklistState[] | undefined;
  roomSurveyWorklistItemStateEnum: RoomSurveyWorklistItemState[] | undefined;
}

export class GeolocationPosition {
  latitude: number | undefined;
  longitude: number | undefined;
}

export class HotacReservationDetailsResponse {
  hotelName: string | undefined;
  idTicket: number | undefined;
  idHotelOrderContainer: number | undefined;
  referenceDate: moment.Moment | undefined;
  invoiceFileUrl: string | undefined;
  roomListFileUrl: string | undefined;
}

export class HotacSurveyReadResponse {
  hotelName: string | undefined;
  idHotelRoom: number | undefined;
  singleRooms: number | undefined;
  doubleRooms: number | undefined;
  tripleRooms: number | undefined;
  singleRate: number | undefined;
  doubleRate: number | undefined;
  tripleRate: number | undefined;
  lastUpdated: moment.Moment | undefined;
}

export class HotacSurveySaveCommand {
  idHotelRoom: number | undefined;
  idContract: number | undefined;
  singleRoom: number | undefined;
  singleRate: number | undefined;
  doubleRoom: number | undefined;
  doubleRate: number | undefined;
  tripleRoom: number | undefined;
  tripleRate: number | undefined;
  isFull: boolean | undefined;
}

export class Hotel {
  idHotel: number | undefined;
  hotelName: string | undefined;
  distanceFromAirport: HotelHasAirport[] | undefined;
  hotelRooms: number | undefined;
  doubleRoomsRate: number | undefined;
  username: string | undefined;
  roomSurveyWorklistItemState: RoomSurveyWorklistItemStateEnum | undefined;
}

export class HotelAvailabilityItem {
  idContract: number | undefined;
  contractType: ContractType | undefined;
  singole: number | undefined;
  doppie: number | undefined;
  triple: number | undefined;
  tariffaSingola: number | undefined;
  tariffaDoppia: number | undefined;
  tariffaTripla: number | undefined;
  bestAvailableRateSalePercentageSingle: number | undefined;
  bestAvailableRateSalePercentageDouble: number | undefined;
  bestAvailableRateSalePercentageTriple: number | undefined;
  lunchRate: number | undefined;
  dinnerRate: number | undefined;
  lunchboxRate: number | undefined;
  restaurantServiceNotes: string | undefined;
  totaleCamere: number | undefined;
  totaleCamereInCarrello: number | undefined;
  totalePersone: number | undefined;
  totalePersoneInCarrello: number | undefined;
  idHotel: number | undefined;
  idHotelRoom: number | undefined;
  idHotelRoomResult: number | undefined;
  idHotelRoomCartBooking: number | undefined;
  idHotelRoomResultType: HotelRoomResultTypeEnum | undefined;
  idHotelRoomInformationSource: HotelRoomInformationSourceEnum | undefined;
  data: moment.Moment | undefined;
  singoleInCarrello: number | undefined;
  doppieInCarrello: number | undefined;
  tripleInCarrello: number | undefined;
  location: GeolocationPosition | undefined;
  hotelRateUpdatedAt: moment.Moment | undefined;
  hotelRateUserUpdatedBy: string | undefined;
  note: string | undefined;
  hasRestaurant: boolean | undefined;
  hotel: HotelSummary | undefined;
  idUserUpdatedBy: number | undefined;
}

export class HotelBooking {
  idHotelRoomCartBooking: number | undefined;
  idHotel: number | undefined;
  idHotelRoomDailyAvailability: number | undefined;
  idHotelRoomResult: number | undefined;
  singleRooms: number | undefined;
  doubleRooms: number | undefined;
  tripleRooms: number | undefined;
  hasLunchbox: boolean | undefined;
  hasLunch: boolean | undefined;
  hasDinner: boolean | undefined;
}

export class HotelDetails {
  idHotel: number | undefined;
  name: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  address: string | undefined;
  municipality: string | undefined;
  zipCode: string | undefined;
  notes: string | undefined;
  timeZoneName: string | undefined;
  hasRestaurant: boolean | undefined;
  lunchRate: number | undefined;
  dinnerRate: number | undefined;
  lunchboxRate: number | undefined;
  restaurantServiceNotes: string | undefined;
}

export class HotelHasAirport {
  idAirport: number | undefined;
  airportIataCode: string | undefined;
  distanceFromHotel: number | undefined;
}

export class HotelOrder {
  idHotelOrder: number | undefined;
  idTicket: number | undefined;
  idCurrency: number | undefined;
  date: moment.Moment | undefined;
  bookingDate: moment.Moment | undefined;
  confirmedDate: moment.Moment | undefined;
  passengersCount: number | undefined;
  buyLunchboxCount: number | undefined;
  buyLunchCount: number | undefined;
  buyDinnerCount: number | undefined;
  buySingleRoomsCount: number | undefined;
  buyDoubleRoomsCount: number | undefined;
  buyTripleRoomsCount: number | undefined;
  buySingleRoomRate: number | undefined;
  buyDoubleRoomRate: number | undefined;
  buyTripleRoomRate: number | undefined;
  buyDiscountPercentageSingle: number | undefined;
  buyDiscountPercentageDouble: number | undefined;
  buyDiscountPercentageTriple: number | undefined;
  buyLunchboxRate: number | undefined;
  buyLunchRate: number | undefined;
  buyDinnerRate: number | undefined;
  buyDiscountAmount: number | undefined;
  buyAmount: number | undefined;
  sellSingleRoomRate: number | undefined;
  sellDoubleRoomRate: number | undefined;
  sellTripleRoomRate: number | undefined;
  sellDiscountPercentageSingle: number | undefined;
  sellDiscountPercentageDouble: number | undefined;
  sellDiscountPercentageTriple: number | undefined;
  sellLunchboxRate: number | undefined;
  sellLunchRate: number | undefined;
  sellDinnerRate: number | undefined;
  sellDiscountAmount: number | undefined;
  sellAmount: number | undefined;
  notes: string | undefined;
  cityTax: number | undefined;
  totalPax: number | undefined;
  totalRooms: number | undefined;
  totalBuyRoomsAmount: number | undefined;
  totalSellRoomsAmount: number | undefined;
  totalBuyFoodsAmount: number | undefined;
  totalSellFoodsAmount: number | undefined;
  hasFoods: boolean | undefined;
  hasCityTax: boolean | undefined;
}

export class HotelOrderContainerDetailsQueryResponse {
  idHotel: number | undefined;
  idTicket: number | undefined;
  nextReservationDate: moment.Moment | undefined;
  hotelName: string | undefined;
  hotelPrefix: string | undefined;
  hotelPhoneNumber: string | undefined;
  items: HotelOrder[] | undefined;
}

export class HotelOrderContainerListByIdServiceRequestAirportToHotelQueryResponse {
  items: HotelOrderContainerListItem[] | undefined;
}

export class HotelOrderContainerListByIdTicketQueryResponse {
  items: HotelOrderContainerListItem[] | undefined;
}

export class HotelOrderContainerListItem {
  idHotelOrderContainer: number | undefined;
  idHotel: number | undefined;
  idCurrency: number | undefined;
  bookingDate: moment.Moment | undefined;
  passengersCount: number | undefined;
  hotelOrderCount: number | undefined;
  totalBuyFoodsAmount: number | undefined;
  totalBuyRoomsAmount: number | undefined;
  totalCityTaxAmount: number | undefined;
  totalSellFoodsAmount: number | undefined;
  totalSellRoomsAmount: number | undefined;
  notes: string | undefined;
  firstNightTotalPax: number | undefined;
  firstNightTotalRooms: number | undefined;
  hotel: HotelSummary | undefined;
}

export class HotelOrderCreateCommand {
  idHotelOrderContainer: number | undefined;
  idTicket: number | undefined;
  idHotel: number | undefined;
  date: moment.Moment | undefined;
  passengersCount: number | undefined;
  hasLunchbox: boolean | undefined;
  hasLunch: boolean | undefined;
  hasDinner: boolean | undefined;
  singleRoomsCount: number | undefined;
  doubleRoomsCount: number | undefined;
  tripleRoomsCount: number | undefined;
  notes: string | undefined;
}

export class HotelOrderReadQueryResponse {
  hotelOrder: HotelOrder | undefined;
  buySellSummary: BuySellSummary | undefined;
}

export class HotelOrderUpdateCommand {
  idHotelOrder: number | undefined;
  passengersCount: number | undefined;
  lunchboxCount: number | undefined;
  lunchCount: number | undefined;
  dinnerCount: number | undefined;
  singleRoomsCount: number | undefined;
  doubleRoomsCount: number | undefined;
  tripleRoomsCount: number | undefined;
}

export class HotelPagedResponse {
  items: Hotel[] | undefined;
  totalCount: number | undefined;
  pageIndex: number | undefined;
  pageSize: number | undefined;
  totalPages: number | undefined;
}

export class HotelResponse {
  idHotel: number | undefined;
  uniqueCode: string | undefined;
  name: string | undefined;
  address: string | undefined;
  zipCode: string | undefined;
  municipality: string | undefined;
  hotelCategory: number | undefined;
  timeZoneName: string | undefined;
  notes: string | undefined;
  latitude: number | undefined;
  longitude: number | undefined;
}

export class HotelRoom {
  idHotelRoom: number | undefined;
  idHotelRoomOccupancy: HotelRoomOccupancyEnum | undefined;
  idHotelRoomExclusiveService: HotelRoomExclusiveServiceEnum | undefined;
  hotelRoomPlanRate: HotelRoomPlanRate | undefined;
}

export class HotelRoomCartBooking {
  idContract: number | undefined;
  contractType: ContractType | undefined;
  singole: number | undefined;
  doppie: number | undefined;
  triple: number | undefined;
  singoleInCarrello: number | undefined;
  doppieInCarrello: number | undefined;
  tripleInCarrello: number | undefined;
  tariffaSingola: number | undefined;
  tariffaDoppia: number | undefined;
  tariffaTripla: number | undefined;
  bestAvailableRateSalePercentageSingle: number | undefined;
  bestAvailableRateSalePercentageDouble: number | undefined;
  bestAvailableRateSalePercentageTriple: number | undefined;
  restaurantServiceNotes: string | undefined;
  totaleCamere: number | undefined;
  totaleCamereInCarrello: number | undefined;
  totalePersone: number | undefined;
  totalePersoneInCarrello: number | undefined;
  idHotelRoomCartBooking: number | undefined;
  idHotel: number | undefined;
  idHotelRoomDailyAvailability: number | undefined;
  idCameraHotelEsito: number | undefined;
  idServiceRequestAirpotToHotel: number | undefined;
  hasDinner: boolean | undefined;
  dinnerRate: number | undefined;
  hasLunch: boolean | undefined;
  lunchRate: number | undefined;
  hasLunchbox: boolean | undefined;
  lunchboxRate: number | undefined;
  hasRestaurant: boolean | undefined;
  notes: string | undefined;
  isEsitoObsoleto: boolean | undefined;
  nomeUtentePrenotazione: string | undefined;
  dataPrenotazione: moment.Moment | undefined;
  tooltip: string | undefined;
  hotel: HotelSummary | undefined;
  totalRoomsInCartAmount: number | undefined;
  totalFoodInCartAmount: number | undefined;
}

export class HotelRoomCartBookingBookHotelsCommand {
  idServiceRequestAirportToHotel: number | undefined;
  bookings: HotelBooking[] | undefined;
}

export class HotelRoomCartBookingListByIdServiceRequestAirportToHotelQueryResponse {
  items: HotelRoomCartBooking[] | undefined;
}

export class HotelRoomCartBookingSaveCommand {
  idHotelRoomCartBooking: number | undefined;
  idHotelRoomDailyAvailability: number | undefined;
  idHotel: number | undefined;
  idHotelRoomResult: number | undefined;
  idRichiestaServizioAeroportoToHotel: number | undefined;
  giorno: moment.Moment | undefined;
  singole: number | undefined;
  doppie: number | undefined;
  triple: number | undefined;
  tariffaSingola: number | undefined;
  tariffaDoppia: number | undefined;
  tariffaTripla: number | undefined;
  hasLunchbox: boolean | undefined;
  hasLunch: boolean | undefined;
  hasDinner: boolean | undefined;
  notes: string | undefined;
}

export class HotelRoomExclusiveService {
  idHotelRoomExclusiveService: HotelRoomExclusiveServiceEnum | undefined;
  name: string | undefined;
}

export enum HotelRoomExclusiveServiceEnum {
  ROOM_ONLY = 1,
  DAY_USE = 2,
  BED_AND_BREAKFAST = 3,
}

export class HotelRoomGroup {
  idHotelRoomGroup: number | undefined;
  idHotelRoomType: HotelRoomTypeEnum | undefined;
  hotelRooms: HotelRoom[] | undefined;
}

export class HotelRoomInformationSource {
  idHotelRoomInformationSource: HotelRoomInformationSourceEnum | undefined;
  name: string | undefined;
}

export enum HotelRoomInformationSourceEnum {
  HOTAC = 1,
  BAKUUN = 2,
  VERTICAL_BOOKING = 3,
  DAILY_SURVEY = 4,
  REPROTECTION_SURVEY = 5,
}

export class HotelRoomOccupancy {
  idHotelRoomOccupancy: HotelRoomOccupancyEnum | undefined;
  name: string | undefined;
}

export enum HotelRoomOccupancyEnum {
  SINGLE = 1,
  DOUBLE_SINGLE_USE = 2,
  DOUBLE = 3,
  TRIPLE = 4,
  QUADRUPLE = 5,
}

export class HotelRoomPlanRate {
  idHotelRoomPlanRate: number | undefined;
  amount: number | undefined;
}

export class HotelRoomResultDetails {
  idContract: number | undefined;
  contractType: ContractType | undefined;
  singole: number | undefined;
  doppie: number | undefined;
  triple: number | undefined;
  singoleInCarrello: number | undefined;
  doppieInCarrello: number | undefined;
  tripleInCarrello: number | undefined;
  tariffaSingola: number | undefined;
  tariffaDoppia: number | undefined;
  tariffaTripla: number | undefined;
  bestAvailableRateSalePercentageSingle: number | undefined;
  bestAvailableRateSalePercentageDouble: number | undefined;
  bestAvailableRateSalePercentageTriple: number | undefined;
  lunchRate: number | undefined;
  dinnerRate: number | undefined;
  lunchboxRate: number | undefined;
  restaurantServiceNotes: string | undefined;
  totaleCamere: number | undefined;
  totaleCamereInCarrello: number | undefined;
  totalePersone: number | undefined;
  totalePersoneInCarrello: number | undefined;
  idHotel: number | undefined;
  idHotelRoomAvailability: number | undefined;
  idHotelRoomResult: number | undefined;
  idHotelRoomResultType: HotelRoomResultTypeEnum | undefined;
  nomeHotel: string | undefined;
  prefissoHotel: string | undefined;
  telefonoHotel: string | undefined;
  indirizzoHotel: string | undefined;
  cittaHotel: string | undefined;
  capHotel: string | undefined;
  giorno: moment.Moment | undefined;
  note: string | undefined;
}

export class HotelRoomResultSaveCommand {
  idHotel: number | undefined;
  idHotelRoom: number | undefined;
  idHotelRoomResult: number | undefined;
  idHotelRoomResultType: HotelRoomResultTypeEnum | undefined;
  date: moment.Moment | undefined;
  note: string | undefined;
  singole: number | undefined;
  tariffaSingola: number | undefined;
  discountPercentageSingle: number | undefined;
  doppie: number | undefined;
  tariffaDoppia: number | undefined;
  discountPercentageDouble: number | undefined;
  triple: number | undefined;
  tariffaTripla: number | undefined;
  discountPercentageTriple: number | undefined;
  lunchRate: number | undefined;
  dinnerRate: number | undefined;
  lunchboxRate: number | undefined;
  isWorklist: boolean | undefined;
}

export class HotelRoomResultType {
  idHotelRoomResultType: HotelRoomResultTypeEnum | undefined;
  name: string | undefined;
}

export enum HotelRoomResultTypeEnum {
  AVAILABLE = 1,
  NOT_AVAILABLE = 2,
  TO_RECONTACT = 3,
}

export class HotelRoomType {
  idHotelRoomType: HotelRoomTypeEnum | undefined;
  name: string | undefined;
}

export enum HotelRoomTypeEnum {
  STANDARD = 1,
  SUPERIOR = 2,
}

export class HotelSummary {
  idHotel: number | undefined;
  airports: string | undefined;
  name: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  address: string | undefined;
  zipCode: string | undefined;
  municipality: string | undefined;
  hotelCategory: number | undefined;
  timeZoneName: string | undefined;
  notes: string | undefined;
  latitude: number | undefined;
  longitude: number | undefined;
  hotelRooms: number | undefined;
  haveRooms: boolean | undefined;
  idContractType: ContractTypeEnum | undefined;
}

export class HotelSurveyDetails {
  idHotelRoomDailyAvailability: number | undefined;
  date: moment.Moment | undefined;
  singleRooms: number | undefined;
  doubleRooms: number | undefined;
  tripleRooms: number | undefined;
  lastUpdatedBy: string | undefined;
  updatedAt: moment.Moment | undefined;
  updatedByUser: string | undefined;
  updatedByUserType: UserTypeEnum | undefined;
  idHotelRoomInformationSource: HotelRoomInformationSourceEnum | undefined;
}

export class HotelSurveyItem {
  idHotelRoom: number | undefined;
  idHotelRate: number | undefined;
  singleRooms: number | undefined;
  singleRate: number | undefined;
  doubleRooms: number | undefined;
  doubleRate: number | undefined;
  tripleRooms: number | undefined;
  tripleRate: number | undefined;
  availabilityDate: moment.Moment | undefined;
  updatedAt: moment.Moment | undefined;
  idUserUpdatedBy: number | undefined;
  userUpdatedByFullname: string | undefined;
  hotel: HotelSummary | undefined;
  idContractType: ContractTypeEnum | undefined;
  hotelRoomInformationSource: HotelRoomInformationSource | undefined;
  idHotelRoomInformationSource: HotelRoomInformationSourceEnum | undefined;
  roomsTotal: number | undefined;
}

export class HotelSurveyListQuery {
  idHotel: number | undefined;
  hotelName: string | undefined;
  airportName: string | undefined;
  date: moment.Moment | undefined;
  idUser: number | undefined;
  idHotelRoomInformationSource: HotelRoomInformationSourceEnum | undefined;
  withoutInformationSource: boolean | undefined;
  idContractType: ContractTypeEnum | undefined;
}

export class HotelSurveyListQueryResponse {
  hotelSurveyItems: HotelSurveyItem[] | undefined;
}

export class HotelSurveyReadResponse {
  hotelName: string | undefined;
  items: HotelSurveyDetails[] | undefined;
}

export class HotelSurveySaveCommand {
  idHotel: number | undefined;
  idHotelRoom: number | undefined;
  date: moment.Moment | undefined;
  singleRooms: number | undefined;
  doubleRooms: number | undefined;
  tripleRooms: number | undefined;
}

export class InvoiceTransportOrderSaveByTransportOrdersCommand {
  number: string | undefined;
  idsTransportOrder: number[] | undefined;
}

export class LanguageCulture {
  idLanguageCulture: number | undefined;
  languageCultureName: string | undefined;
  countryName: string | undefined;
}

export class LanguageCultureListQueryResponse {
  items: LanguageCulture[] | undefined;
}

export class ListServiceRequestResponse {
  items: ServiceRequestResponse[] | undefined;
}

export class MenuItem {
  sectionTitle: string | undefined;
  link: string | undefined;
  title: string | undefined;
  icon: string | undefined;
  children: MenuItem[] | undefined;
}

export class MenuReadByCurrentUserQueryResponse {
  items: MenuItem[] | undefined;
}

export class PassengerGroupListByIdServiceAirportToHotelResponse {
  items: PassengersInService[] | undefined;
}

export class PassengerGroupListResponse {
  items: PassengerGroupResponse[] | undefined;
}

export class PassengerGroupListUploadType {
  idPassengerGroupListUploadType: PassengerGroupListUploadTypeEnum | undefined;
  name: string | undefined;
}

export enum PassengerGroupListUploadTypeEnum {
  NEOS_COPY_AND_PASTE = 1,
}

export class PassengerGroupListUploadTypeResponse {
  idPassengerGroupListUploadType: PassengerGroupListUploadTypeEnum | undefined;
  name: string | undefined;
}

export class PassengerGroupNameFinalizeRequest {
  idPassengerGroupName: number | undefined;
  idPassengerGroup: number | undefined;
  surname: string | undefined;
  name: string | undefined;
  idPassengerGroupRole: PassengerGroupRoleEnum | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
}

export class PassengerGroupNameResponse {
  idPassengerGroupName: number | undefined;
  idPassengerGroup: number | undefined;
  idPassengerGroupRole: PassengerGroupRoleEnum | undefined;
  idAirport: number | undefined;
  name: string | undefined;
  surname: string | undefined;
  departureDate: moment.Moment | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
}

export class PassengerGroupNameSaveRequest {
  idPassengerGroupName: number | undefined;
  idPassengerGroup: number | undefined;
  surname: string | undefined;
  name: string | undefined;
  idPassengerGroupRole: PassengerGroupRoleEnum | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  needTransport: boolean | undefined;
}

export class PassengerGroupResponse {
  idPassengerGroup: number | undefined;
  email: string | undefined;
  name: string | undefined;
  surname: string | undefined;
  isRegistered: boolean | undefined;
  hasServiceAirportToHotel: boolean | undefined;
  passengersGroupNames: PassengerGroupNameResponse[] | undefined;
}

export class PassengerGroupRole {
  idPassengerGroupRole: PassengerGroupRoleEnum | undefined;
  name: string | undefined;
}

export enum PassengerGroupRoleEnum {
  NORMAL = 1,
  GROUP_LEADER = 2,
}

export class PassengerGroupSaveCommand {
  idPassengerGroup: number | undefined;
  idTicket: number | undefined;
  email: string | undefined;
  confirmedDate: moment.Moment | undefined;
  name: string | undefined;
  surname: string | undefined;
  idLanguageCulture: number | undefined;
  singleRooms: number | undefined;
  doubleRooms: number | undefined;
  tripleRooms: number | undefined;
  needTransport: boolean | undefined;
  passengersGroupNames: PassengerGroupNameSaveRequest[] | undefined;
}

export class PassengerParseRequestCreateCommand {
  idTicket: number | undefined;
  stringToParse: string | undefined;
}

export class PassengerRegistrationActivateCommand {
  idPassengerGroup: number | undefined;
  idTicket: number | undefined;
  activationCode: string | undefined;
}

export class PassengerRegistrationFinalizeCommand {
  idPassengerGroup: number | undefined;
  idTicket: number | undefined;
  idUser: number | undefined;
  email: string | undefined;
  name: string | undefined;
  surname: string | undefined;
  needTransport: boolean | undefined;
  passengersCount: number | undefined;
  singleRooms: number | undefined;
  doubleRooms: number | undefined;
  tripleRooms: number | undefined;
  quadrupleRooms: number | undefined;
  isRegistered: boolean | undefined;
  passengersGroupNames: PassengerGroupNameFinalizeRequest[] | undefined;
}

export class PassengerRegistrationGenerateCodeCommand {
  idPassengerGroup: number | undefined;
  idTicket: number | undefined;
  idLanguageCulture: number | undefined;
  email: string | undefined;
  path: string | undefined;
}

export class PassengerRegistrationTokenCommand {
  idPassengerGroup: number | undefined;
  idTicket: number | undefined;
  activationCode: string | undefined;
  ipAddress: string | undefined;
}

export class PassengerRegistrationType {
  idPassengerRegistrationType: PassengerRegistrationTypeEnum | undefined;
  name: string | undefined;
}

export enum PassengerRegistrationTypeEnum {
  WITH_REGISTRATION = 1,
  WITHOUT_REGISTRATION = 2,
  IMPORTED_FROM_LIST = 3,
}

export class PassengersInService {
  idPassengerGroup: number | undefined;
  idServiceAirportToHotelHasPassengerGroup: number | undefined;
  name: string | undefined;
  surname: string | undefined;
}

export class ProformaInvoiceCustomerHotelOrderContainer {
  idHotelOrderContainer: number | undefined;
  hotelName: string | undefined;
  bookingDate: moment.Moment | undefined;
  nights: number | undefined;
  sellRoomAmount: number | undefined;
  sellMealAmount: number | undefined;
  sellServicesAmount: number | undefined;
  sellCityTaxAmount: number | undefined;
  sellTotalAmount: number | undefined;
}

export class ProformaInvoiceCustomerHotelOrderContainerDetailsQueryResponse {
  idTicket: number | undefined;
  idHotelOrderContainer: number | undefined;
  hotelName: string | undefined;
  sellTotalAmountOrder: number | undefined;
  items: ProformaInvoiceCustomerHotelOrderContainerItem[] | undefined;
}

export class ProformaInvoiceCustomerHotelOrderContainerItem {
  idHotelOrderContainer: number | undefined;
  passengersCount: number | undefined;
  idHotelOrder: number | undefined;
  bookingDate: moment.Moment | undefined;
  sellSingleRoomsCount: number | undefined;
  sellDoubleRoomsCount: number | undefined;
  sellTripleRoomsCount: number | undefined;
  sellSingleRoomRate: number | undefined;
  sellDoubleRoomRate: number | undefined;
  sellTripleRoomRate: number | undefined;
  sellLunchboxCount: number | undefined;
  sellLunchCount: number | undefined;
  sellDinnerCount: number | undefined;
  sellLunchboxRate: number | undefined;
  sellLunchRate: number | undefined;
  sellDinnerRate: number | undefined;
  sellNoShowCount: number | undefined;
  sellNoShowRate: number | undefined;
  sellEarlyCheckInCount: number | undefined;
  sellEarlyCheckInRate: number | undefined;
  sellLateCheckOutCount: number | undefined;
  sellLateCheckOutRate: number | undefined;
  sellPetCount: number | undefined;
  sellPetRate: number | undefined;
  sellCityTax: number | undefined;
  sellExtra: number | undefined;
  sellTotalAmount: number | undefined;
  sellExtraNotes: string | undefined;
}

export class ProformaInvoiceCustomerHotelOrderContainerListQueryResponse {
  items: ProformaInvoiceCustomerHotelOrderContainer[] | undefined;
  sellTotalAmount: number | undefined;
}

export class ProformaInvoiceCustomerTransportOrder {
  idTransportOrder: number | undefined;
  pickupDate: moment.Moment | undefined;
  departure: string | undefined;
  destination: string | undefined;
  vehicles: number | undefined;
  sellAmount: number | undefined;
  sellExtraAmount: number | undefined;
  sellTotalAmount: number | undefined;
}

export class ProformaInvoiceCustomerTransportOrderDetailsQueryResponse {
  idTransportOrder: number | undefined;
  idTicket: number | undefined;
  pickupDate: moment.Moment | undefined;
  departure: string | undefined;
  destination: string | undefined;
  ordinal: number | undefined;
  sellTotalAmountOrder: number | undefined;
  items: ProformaInvoiceCustomerTransportOrderItem[] | undefined;
}

export class ProformaInvoiceCustomerTransportOrderItem {
  idTransportOrderItem: number | undefined;
  pickupDate: moment.Moment | undefined;
  driverName: string | undefined;
  capacity: number | undefined;
  notes: string | undefined;
  sellRouteAmount: number | undefined;
  sellExtraAmount: number | undefined;
  sellNocturneOrFestiveAmount: number | undefined;
  sellZtlAmount: number | undefined;
  sellDriverMealAmount: number | undefined;
  sellOvernightDriverAmount: number | undefined;
  sellExtraDriverAmount: number | undefined;
  sellExtraNotes: string | undefined;
  sellTotalAmount: number | undefined;
}

export class ProformaInvoiceCustomerTransportOrderListQueryResponse {
  items: ProformaInvoiceCustomerTransportOrder[] | undefined;
  sellTotalAmount: number | undefined;
}

export class ProformaInvoiceHotelOrderContainer {
  idHotelOrderContainer: number | undefined;
  hotelName: string | undefined;
  bookingDate: moment.Moment | undefined;
  nights: number | undefined;
  invoiceFileUrl: string | undefined;
  roomListFileUrl: string | undefined;
  buyRoomAmount: number | undefined;
  buyMealAmount: number | undefined;
  buyServicesAmount: number | undefined;
  buyCityTaxAmount: number | undefined;
  buyTotalAmount: number | undefined;
  sellRoomAmount: number | undefined;
  sellMealAmount: number | undefined;
  sellServicesAmount: number | undefined;
  sellCityTaxAmount: number | undefined;
  sellTotalAmount: number | undefined;
  invoiceWorked: boolean | undefined;
}

export class ProformaInvoiceHotelOrderContainerDetailsQueryResponse {
  idTicket: number | undefined;
  idHotelOrderContainer: number | undefined;
  hotelName: string | undefined;
  invoiceFileUrl: string | undefined;
  roomListFileUrl: string | undefined;
  buyTotalAmountOrder: number | undefined;
  sellTotalAmountOrder: number | undefined;
  items: ProformaInvoiceHotelOrderContainerItem[] | undefined;
}

export class ProformaInvoiceHotelOrderContainerItem {
  idHotelOrderContainer: number | undefined;
  idHotelOrder: number | undefined;
  bookingDate: moment.Moment | undefined;
  passengersCount: number | undefined;
  buySingleRoomsCount: number | undefined;
  buyDoubleRoomsCount: number | undefined;
  buyTripleRoomsCount: number | undefined;
  buySingleRoomRate: number | undefined;
  buyDoubleRoomRate: number | undefined;
  buyTripleRoomRate: number | undefined;
  buyDiscountPercentageSingle: number | undefined;
  buyDiscountPercentageDouble: number | undefined;
  buyDiscountPercentageTriple: number | undefined;
  buyLunchboxCount: number | undefined;
  buyLunchCount: number | undefined;
  buyDinnerCount: number | undefined;
  buyLunchboxRate: number | undefined;
  buyLunchRate: number | undefined;
  buyDinnerRate: number | undefined;
  buyDiscountAmount: number | undefined;
  buyNoShowCount: number | undefined;
  buyNoShowRate: number | undefined;
  buyEarlyCheckInCount: number | undefined;
  buyEarlyCheckInRate: number | undefined;
  buyLateCheckOutCount: number | undefined;
  buyLateCheckOutRate: number | undefined;
  buyPetCount: number | undefined;
  buyPetRate: number | undefined;
  buyCityTax: number | undefined;
  buyExtra: number | undefined;
  buyExtraNotes: string | undefined;
  buyAmount: number | undefined;
  sellSingleRoomsCount: number | undefined;
  sellDoubleRoomsCount: number | undefined;
  sellTripleRoomsCount: number | undefined;
  sellSingleRoomRate: number | undefined;
  sellDoubleRoomRate: number | undefined;
  sellTripleRoomRate: number | undefined;
  sellDiscountPercentageSingle: number | undefined;
  sellDiscountPercentageDouble: number | undefined;
  sellDiscountPercentageTriple: number | undefined;
  sellLunchboxCount: number | undefined;
  sellLunchCount: number | undefined;
  sellDinnerCount: number | undefined;
  sellLunchboxRate: number | undefined;
  sellLunchRate: number | undefined;
  sellDinnerRate: number | undefined;
  sellDiscountAmount: number | undefined;
  sellNoShowCount: number | undefined;
  sellNoShowRate: number | undefined;
  sellEarlyCheckInCount: number | undefined;
  sellEarlyCheckInRate: number | undefined;
  sellLateCheckOutCount: number | undefined;
  sellLateCheckOutRate: number | undefined;
  sellPetCount: number | undefined;
  sellPetRate: number | undefined;
  sellCityTax: number | undefined;
  sellExtra: number | undefined;
  sellExtraNotes: string | undefined;
  sellAmount: number | undefined;
  notes: string | undefined;
  invoiceBuyWorked: boolean | undefined;
  invoiceSellWorked: boolean | undefined;
}

export class ProformaInvoiceHotelOrderContainerListQueryResponse {
  items: ProformaInvoiceHotelOrderContainer[] | undefined;
  buyTotalAmount: number | undefined;
  sellTotalAmount: number | undefined;
}

export class ProformaInvoiceHotelOrderReadCustomerRatesQueryResponse {
  idCustomerContractType: CustomerContractTypeEnum | undefined;
  ratePercentage: number | undefined;
  singleRate: number | undefined;
  doubleRate: number | undefined;
  tripleRate: number | undefined;
  lunchRate: number | undefined;
  dinnerRate: number | undefined;
  lunchboxRate: number | undefined;
}

export class ProformaInvoiceHotelOrderSaveCommand {
  idHotelOrder: number | undefined;
  buySingleRoomsCount: number | undefined;
  buyDoubleRoomsCount: number | undefined;
  buyTripleRoomsCount: number | undefined;
  buySingleRoomRate: number | undefined;
  buyDoubleRoomRate: number | undefined;
  buyTripleRoomRate: number | undefined;
  buyLunchboxCount: number | undefined;
  buyLunchCount: number | undefined;
  buyDinnerCount: number | undefined;
  buyLunchboxRate: number | undefined;
  buyLunchRate: number | undefined;
  buyDinnerRate: number | undefined;
  buyNoShowCount: number | undefined;
  buyNoShowRate: number | undefined;
  buyEarlyCheckInCount: number | undefined;
  buyEarlyCheckInRate: number | undefined;
  buyLateCheckOutCount: number | undefined;
  buyLateCheckOutRate: number | undefined;
  buyPetCount: number | undefined;
  buyPetRate: number | undefined;
  buyCityTax: number | undefined;
  buyExtra: number | undefined;
  buyExtraNotes: string | undefined;
  sellSingleRoomsCount: number | undefined;
  sellDoubleRoomsCount: number | undefined;
  sellTripleRoomsCount: number | undefined;
  sellSingleRoomRate: number | undefined;
  sellDoubleRoomRate: number | undefined;
  sellTripleRoomRate: number | undefined;
  sellLunchboxCount: number | undefined;
  sellLunchCount: number | undefined;
  sellDinnerCount: number | undefined;
  sellLunchboxRate: number | undefined;
  sellLunchRate: number | undefined;
  sellDinnerRate: number | undefined;
  sellNoShowCount: number | undefined;
  sellNoShowRate: number | undefined;
  sellEarlyCheckInCount: number | undefined;
  sellEarlyCheckInRate: number | undefined;
  sellLateCheckOutCount: number | undefined;
  sellLateCheckOutRate: number | undefined;
  sellPetCount: number | undefined;
  sellPetRate: number | undefined;
  sellCityTax: number | undefined;
  sellExtra: number | undefined;
  sellExtraNotes: string | undefined;
  invoiceBuyWorked: boolean | undefined;
  invoiceSellWorked: boolean | undefined;
}

export class ProformaInvoiceListItem {
  proformaInvoiceListItemType: ProformaInvoiceListItemType | undefined;
  quantity: number | undefined;
  passengers: number | undefined;
  buyTotalAmount: number | undefined;
  sellTotalAmount: number | undefined;
  worked: boolean | undefined;
}

export enum ProformaInvoiceListItemType {
  HOTEL = 1,
  TRANSPORT = 2,
  SNACK = 3,
}

export class ProformaInvoiceReadSummaryQueryResponse {
  items: ProformaInvoiceListItem[] | undefined;
  publishedAt: moment.Moment | undefined;
  publishedBy: number | undefined;
}

export class ProformaInvoiceTransportOrder {
  idTransportOrder: number | undefined;
  pickupDate: moment.Moment | undefined;
  departure: string | undefined;
  destination: string | undefined;
  vehicles: number | undefined;
  buyAmount: number | undefined;
  buyExtraAmount: number | undefined;
  buyTotalAmount: number | undefined;
  sellAmount: number | undefined;
  sellExtraAmount: number | undefined;
  sellTotalAmount: number | undefined;
  invoiceWorked: boolean | undefined;
}

export class ProformaInvoiceTransportOrderDetailsQueryResponse {
  idTransportOrder: number | undefined;
  idTicket: number | undefined;
  pickupDate: moment.Moment | undefined;
  departure: string | undefined;
  destination: string | undefined;
  ordinal: number | undefined;
  buyTotalAmountOrder: number | undefined;
  sellTotalAmountOrder: number | undefined;
  customerContractTransportRateAttachments: Attachment[] | undefined;
  items: ProformaInvoiceTransportOrderItem[] | undefined;
}

export class ProformaInvoiceTransportOrderItem {
  idTransportOrderItem: number | undefined;
  pickupDate: moment.Moment | undefined;
  driverName: string | undefined;
  buyRouteAmount: number | undefined;
  buyExtraAmount: number | undefined;
  buyNocturneOrFestiveAmount: number | undefined;
  buyZtlAmount: number | undefined;
  buyDriverMealAmount: number | undefined;
  buyOvernightDriverAmount: number | undefined;
  buyExtraDriverAmount: number | undefined;
  buyExtraNotes: string | undefined;
  buyTotalAmount: number | undefined;
  amountNotes: string | undefined;
  invoiceBuyWorked: boolean | undefined;
  sellRouteAmount: number | undefined;
  sellExtraAmount: number | undefined;
  sellNocturneOrFestiveAmount: number | undefined;
  sellZtlAmount: number | undefined;
  sellDriverMealAmount: number | undefined;
  sellOvernightDriverAmount: number | undefined;
  sellExtraDriverAmount: number | undefined;
  sellExtraNotes: string | undefined;
  sellTotalAmount: number | undefined;
  invoiceSellWorked: boolean | undefined;
}

export class ProformaInvoiceTransportOrderItemSaveCommand {
  idTransportOrderItem: number | undefined;
  buyRouteAmount: number | undefined;
  buyExtraAmount: number | undefined;
  buyNocturneOrFestiveAmount: number | undefined;
  buyZtlAmount: number | undefined;
  buyDriverMealAmount: number | undefined;
  buyOvernightDriverAmount: number | undefined;
  buyExtraDriverAmount: number | undefined;
  buyExtraNotes: string | undefined;
  sellRouteAmount: number | undefined;
  sellExtraAmount: number | undefined;
  sellNocturneOrFestiveAmount: number | undefined;
  sellZtlAmount: number | undefined;
  sellDriverMealAmount: number | undefined;
  sellOvernightDriverAmount: number | undefined;
  sellExtraDriverAmount: number | undefined;
  sellExtraNotes: string | undefined;
  invoiceBuyWorked: boolean | undefined;
  invoiceSellWorked: boolean | undefined;
}

export class ProformaInvoiceTransportOrderListQueryResponse {
  items: ProformaInvoiceTransportOrder[] | undefined;
  buyTotalAmount: number | undefined;
  sellTotalAmount: number | undefined;
}

export class ProformaInvoiceTransportOrderReadCustomerRatesQueryResponse {
  ratePercentage: number | undefined;
}

export class ReprotectionListQuery {
  idServiceRequestAirportToHotel: number | undefined;
  filter: string | undefined;
  idContractType: ContractTypeEnum | undefined;
  idHotelRoomInformationSource: HotelRoomInformationSourceEnum | undefined;
  date: moment.Moment | undefined;
  reprotectionListType: ReprotectionListType | undefined;
}

export class ReprotectionListQueryResponse {
  items: HotelAvailabilityItem[] | undefined;
}

export enum ReprotectionListType {
  TO_CONTACT = 1,
  AVAILABLE = 2,
  NOT_AVAILABLE = 3,
  BOOKABLE = 4,
}

export class Restaurant {
  idRestaurant: number | undefined;
  idAirport: number | undefined;
  idSupplierFoodServiceCompany: number | undefined;
  name: string | undefined;
  director: string | undefined;
  email: string | undefined;
  idLanguageCulture: number | undefined;
  airport: Airport | undefined;
  languageCulture: LanguageCulture | undefined;
}

export class RestaurantCreateCommand {
  idAirport: number | undefined;
  idSupplierFoodServiceCompany: number | undefined;
  name: string | undefined;
  director: string | undefined;
  email: string | undefined;
  idLanguageCulture: number | undefined;
}

export class RestaurantListQuery {
  idSupplierFoodServiceCompany: number | undefined;
  idAirport: number | undefined;
}

export class RestaurantListQueryResponse {
  items: Restaurant[] | undefined;
}

export class RestaurantUpdateCommand {
  idRestaurant: number | undefined;
  idAirport: number | undefined;
  idSupplierFoodServiceCompany: number | undefined;
  name: string | undefined;
  director: string | undefined;
  email: string | undefined;
  idLanguageCulture: number | undefined;
}

export class Result {
  isSuccess: boolean | undefined;
  message: string | undefined;
  stacktrace: string | undefined;
  exception: ApplicationException | undefined;
}

export class RoomSurveyListSaveResponse {
  items: RoomSurveyWorklistListItemResponse[] | undefined;
}

export class RoomSurveyWorklistCloneCommand {
  idRoomSurveyWorklist: number | undefined;
  date: moment.Moment | undefined;
  name: string | undefined;
  assignedIdUser: number | undefined;
}

export class RoomSurveyWorklistCompletedListQuery {
  date: moment.Moment | undefined;
  name: string | undefined;
  assignedIdUser: number | undefined;
}

export class RoomSurveyWorklistCompletedListQueryResponse {
  items: RoomSurveyWorklistListResponse[] | undefined;
}

export class RoomSurveyWorklistHotelListQuery {
  idRoomSurveyWorklist: number | undefined;
  airport: string | undefined;
  hotelName: string | undefined;
  distance: number | undefined;
  municipality: string | undefined;
  idContractType: ContractTypeEnum | undefined;
  selected: boolean | undefined;
  pageNumber: number | undefined;
  pageSize: number | undefined;
}

export class RoomSurveyWorklistHotelListQueryResponse {
  pagedHotels: HotelPagedResponse | undefined;
}

export class RoomSurveyWorklistItemAllHotelDeleteCommand {
  idRoomSurveyWorklist: number | undefined;
  airport: string | undefined;
  hotelName: string | undefined;
  distance: number | undefined;
  municipality: string | undefined;
  idContractType: ContractTypeEnum | undefined;
}

export class RoomSurveyWorklistItemAllHotelSaveCommand {
  idRoomSurveyWorklist: number | undefined;
  airport: string | undefined;
  hotelName: string | undefined;
  distance: number | undefined;
  municipality: string | undefined;
  idContractType: ContractTypeEnum | undefined;
}

export class RoomSurveyWorklistItemAllHotelUpdateAssignedUserCommand {
  idRoomSurveyWorklist: number | undefined;
  airport: string | undefined;
  hotelName: string | undefined;
  distance: number | undefined;
  municipality: string | undefined;
  idContractType: ContractTypeEnum | undefined;
  assignedIdUser: number | undefined;
}

export class RoomSurveyWorklistItemDeleteCommand {
  idRoomSurveyWorklist: number | undefined;
  idsHotel: number[] | undefined;
}

export class RoomSurveyWorklistItemListQueryResponse {
  items: RoomSurveyWorklistListItemResponse[] | undefined;
}

export class RoomSurveyWorklistItemNotWorkedListQueryResponse {
  items: RoomSurveyWorklistListItemResponse[] | undefined;
}

export class RoomSurveyWorklistItemSaveAvailabilitiesCommand {
  idRoomSurveyWorklistItem: number | undefined;
  idRoomSurveyWorklistState: RoomSurveyWorklistStateEnum | undefined;
}

export class RoomSurveyWorklistItemSaveCommand {
  idRoomSurveyWorklist: number | undefined;
  idsHotel: number[] | undefined;
}

export class RoomSurveyWorklistItemState {
  idRoomSurveyWorklistItemStateEnum: RoomSurveyWorklistItemStateEnum | undefined;
  name: string | undefined;
}

export enum RoomSurveyWorklistItemStateEnum {
  WORKED = 1,
  NOT_WORKED = 2,
}

export class RoomSurveyWorklistItemUpdateAssignedUserCommand {
  idsHotel: number[] | undefined;
  assignedIdUser: number | undefined;
  idRoomSurveyWorklist: number | undefined;
}

export class RoomSurveyWorklistItemWorkedListQueryResponse {
  items: RoomSurveyWorklistListItemResponse[] | undefined;
}

export class RoomSurveyWorklistListItemResponse {
  idContract: number | undefined;
  contractType: ContractType | undefined;
  singole: number | undefined;
  doppie: number | undefined;
  triple: number | undefined;
  singoleInCarrello: number | undefined;
  doppieInCarrello: number | undefined;
  tripleInCarrello: number | undefined;
  tariffaSingola: number | undefined;
  tariffaDoppia: number | undefined;
  tariffaTripla: number | undefined;
  bestAvailableRateSalePercentageSingle: number | undefined;
  bestAvailableRateSalePercentageDouble: number | undefined;
  bestAvailableRateSalePercentageTriple: number | undefined;
  lunchRate: number | undefined;
  dinnerRate: number | undefined;
  lunchboxRate: number | undefined;
  restaurantServiceNotes: string | undefined;
  totaleCamere: number | undefined;
  totaleCamereInCarrello: number | undefined;
  totalePersone: number | undefined;
  totalePersoneInCarrello: number | undefined;
  idRoomSurveyWorklistItem: number | undefined;
  idRoomSurveyWorklist: number | undefined;
  idHotel: number | undefined;
  idRoomSurveyWorklistItemState: RoomSurveyWorklistItemStateEnum | undefined;
  idHotelRoomDailyAvailability: number | undefined;
  idHotelRoomResult: number | undefined;
  idHotelRoomResultType: HotelRoomResultTypeEnum | undefined;
  note: string | undefined;
  hasRestaurant: boolean | undefined;
  date: moment.Moment | undefined;
  hotel: HotelSummary | undefined;
}

export class RoomSurveyWorklistListQuery {
  date: moment.Moment | undefined;
  name: string | undefined;
  assignedIdUser: number | undefined;
}

export class RoomSurveyWorklistListQueryResponse {
  items: RoomSurveyWorklistListResponse[] | undefined;
}

export class RoomSurveyWorklistListResponse {
  idRoomSurveyWorklist: number | undefined;
  idRoomSurveyWorklistState: RoomSurveyWorklistStateEnum | undefined;
  assignedIdUser: number | undefined;
  assignedUserName: string | undefined;
  name: string | undefined;
  date: moment.Moment | undefined;
  totalItems: number | undefined;
  workedItems: number | undefined;
}

export class RoomSurveyWorklistResponse {
  idRoomSurveyWorklist: number | undefined;
  idRoomSurveyWorklistState: RoomSurveyWorklistStateEnum | undefined;
  name: string | undefined;
  date: moment.Moment | undefined;
  totalItems: number | undefined;
  workedItems: number | undefined;
  assignedUser: UserReadQueryResponse | undefined;
}

export class RoomSurveyWorklistSaveCommand {
  idRoomSurveyWorklist: number | undefined;
  date: moment.Moment | undefined;
  name: string | undefined;
  assignedIdUser: number | undefined;
}

export class RoomSurveyWorklistState {
  idRoomSurveyWorklistStateEnum: RoomSurveyWorklistStateEnum | undefined;
  name: string | undefined;
}

export enum RoomSurveyWorklistStateEnum {
  DRAFT = 1,
  ACTIVE = 2,
  IN_PROGRESS = 3,
  COMPLETED = 4,
}

export class RoomSurveyWorklistTodayListQuery {
}

export class RoomSurveyWorklistTodayListQueryResponse {
  items: RoomSurveyWorklistListResponse[] | undefined;
}

export class ServiceAirportToHotelHasPassengerGroupListQueryResponse {
  items: ServiceAirportToHotelHasPassengerGroupListResponse[] | undefined;
}

export class ServiceAirportToHotelHasPassengerGroupListResponse {
  idHotel: number | undefined;
  idServiceAirportToHotel: number | undefined;
  hotelName: string | undefined;
  idHotelOrderContainer: number | undefined;
  invoiceFileUrl: string | undefined;
  roomListFileUrl: string | undefined;
  passengersInService: PassengersInService[] | undefined;
}

export class ServiceAirportToHotelHasPassengerGroupSaveCommand {
  idServiceAirportToHotel: number | undefined;
  idsPassengerGroup: number[] | undefined;
}

export class ServiceHotelToAirportListResponse {
  items: ServiceHotelToAirportResponse[] | undefined;
}

export class ServiceHotelToAirportResponse {
  idServiceHotelToAirport: number | undefined;
  idServiceRequestHotelToAirport: number | undefined;
  idTicket: number | undefined;
  idTransportOrder: number | undefined;
  idHotelDeparture: number | undefined;
  idAirportDestination: number | undefined;
  replacementFlightDate: moment.Moment | undefined;
  pickupDate: moment.Moment | undefined;
  passengersCount: number | undefined;
  canceled: boolean | undefined;
  transportOrder: TransportOrderSvcResponse | undefined;
  hotelDeparture: HotelResponse | undefined;
  airportDestination: AirportResponse | undefined;
}

export class ServiceRequestAirportToAirportCreateCommand {
  idTicket: number | undefined;
  idPassengerRegistrationType: PassengerRegistrationTypeEnum | undefined;
  idAirportDeparture: number | undefined;
  idAirportDestination: number | undefined;
  requestedPickupDate: moment.Moment | undefined;
  passengersCount: number | undefined;
  reducedMobilityPassengers: boolean | undefined;
  notes: string | undefined;
}

export class ServiceRequestAirportToAirportListResponse {
  items: ServiceRequestAirportToAirportResponse[] | undefined;
}

export class ServiceRequestAirportToAirportResponse {
  idServiceRequestAirportToAirport: number | undefined;
  idServiceRequest: number | undefined;
  idTicket: number | undefined;
  idServiceRequestState: ServiceRequestStateEnum | undefined;
  requestedPickupDate: moment.Moment | undefined;
  passengersCount: number | undefined;
  creationDate: moment.Moment | undefined;
  canceledAt: moment.Moment | undefined;
  errorMessage: string | undefined;
  notes: string | undefined;
  airportDeparture: Airport | undefined;
  airportArrival: Airport | undefined;
  passengersGroups: number[] | undefined;
}

export class ServiceRequestAirportToAirportTransportOrderItemListResponse {
  items: TransportOrderItem[] | undefined;
}

export class ServiceRequestAirportToAirportUpdateCommand {
  idServiceRequestAirportToAirport: number | undefined;
  notes: string | undefined;
}

export class ServiceRequestAirportToHotelCreateCommand {
  idTicket: number | undefined;
  passengerRegistrationType: PassengerRegistrationTypeEnum | undefined;
  idAirportDeparture: number | undefined;
  idAirportOvernight: number | undefined;
  needsTransport: boolean | undefined;
  requestedPickupDate: moment.Moment | undefined;
  passengersCount: number | undefined;
  idPassengerGroups: number[] | undefined;
  reducedMobilityPassengers: boolean | undefined;
  notes: string | undefined;
  singleRooms: number | undefined;
  doubleRooms: number | undefined;
  tripleRooms: number | undefined;
  lunchboxRate: boolean | undefined;
  lunchRate: boolean | undefined;
  dinnerRate: boolean | undefined;
}

export class ServiceRequestAirportToHotelListResponse {
  items: ServiceRequestAirportToHotelReadResponse[] | undefined;
}

export class ServiceRequestAirportToHotelReadResponse {
  idServiceRequestAirportToHotel: number | undefined;
  idServiceRequest: number | undefined;
  idTicket: number | undefined;
  idAirportDeparture: number | undefined;
  idAirportOvernight: number | undefined;
  needsTransport: boolean | undefined;
  idServiceRequestState: ServiceRequestStateEnum | undefined;
  requestedPickupDate: moment.Moment | undefined;
  passengersCount: number | undefined;
  reducedMobilityPassengers: boolean | undefined;
  creationDate: moment.Moment | undefined;
  ticketNotes: string | undefined;
  notes: string | undefined;
  singleRooms: number | undefined;
  doubleRooms: number | undefined;
  tripleRooms: number | undefined;
  lunchboxRate: boolean | undefined;
  lunchRate: boolean | undefined;
  dinnerRate: boolean | undefined;
  airportDeparture: Airport | undefined;
  airportOvernight: Airport | undefined;
  passengersGroups: number[] | undefined;
  canceledAt: moment.Moment | undefined;
}

export class ServiceRequestAirportToHotelTransportOrderItemQueryResponse {
  items: ServiceRequestTransportOrderResponse[] | undefined;
}

export class ServiceRequestAirportToHotelUpdateCommand {
  idServiceRequestAirportToHotel: number | undefined;
  notes: string | undefined;
}

export enum ServiceRequestCancellationDebitBehaviorEnum {
  WITHOUT_CHARGE = 1,
  WITH_CHARGE = 2,
  PENDING_CANCELLATION = 3,
}

export class ServiceRequestHotelToAirportCreateCommand {
  idTicket: number | undefined;
  passengerRegistrationType: PassengerRegistrationTypeEnum | undefined;
  idAirportDestination: number | undefined;
  replacementFlightDate: moment.Moment | undefined;
  passengersCount: number | undefined;
  reducedMobilityPassengers: boolean | undefined;
  passengersGroups: number[] | undefined;
  notes: string | undefined;
  executeService: boolean | undefined;
}

export class ServiceRequestHotelToAirportCreateServiceHotelToAirportCommand {
  idTicket: number | undefined;
  idServiceRequest: number | undefined;
  idServiceRequestHotelToAirport: number | undefined;
  idHotelDeparture: number | undefined;
  passengersCount: number | undefined;
}

export class ServiceRequestHotelToAirportListResponse {
  items: ServiceRequestHotelToAirportReadResponse[] | undefined;
}

export class ServiceRequestHotelToAirportReadResponse {
  idServiceRequestHotelToAirport: number | undefined;
  idServiceRequest: number | undefined;
  idTicket: number | undefined;
  idAirportDestination: number | undefined;
  idServiceRequestState: ServiceRequestStateEnum | undefined;
  replacementFlightDate: moment.Moment | undefined;
  passengersCount: number | undefined;
  reducedMobilityPassenger: boolean | undefined;
  notes: string | undefined;
  creationDate: moment.Moment | undefined;
  serviceRequest: ServiceRequestResponse | undefined;
  airportDestination: Airport | undefined;
  passengersGroups: number[] | undefined;
  canceledAt: moment.Moment | undefined;
}

export class ServiceRequestHotelToAirportTransportOrderItemQueryResponse {
  items: ServiceRequestTransportOrderResponse[] | undefined;
}

export class ServiceRequestHotelToAirportUpdateCommand {
  idServiceRequestHotelToAirport: number | undefined;
  notes: string | undefined;
}

export class ServiceRequestResponse {
  idServiceRequest: number | undefined;
  idTicket: number | undefined;
  idServiceRequestType: ServiceRequestTypeEnum | undefined;
  idServiceRequestState: ServiceRequestStateEnum | undefined;
  idCreationUser: number | undefined;
  errorMessage: string | undefined;
  notes: string | undefined;
  creationUser: string | undefined;
  serviceRequestType: ServiceRequestType | undefined;
  serviceRequestState: ServiceRequestState | undefined;
}

export class ServiceRequestSnackCreateCommand {
  idAirlineCompany: number | undefined;
  idTicket: number | undefined;
  passengersCount: number | undefined;
  idSnackType: number | undefined;
}

export class ServiceRequestSnackListQueryResponse {
  items: ServiceRequestSnackResponse[] | undefined;
}

export class ServiceRequestSnackResponse {
  idServiceRequestSnack: number | undefined;
  idServiceRequest: number | undefined;
  idTicket: number | undefined;
  passengersCount: number | undefined;
  numberOfVouchersEmitted: number | undefined;
  createdAt: moment.Moment | undefined;
  canceledAt: moment.Moment | undefined;
  snackType: SnackType | undefined;
  idServiceRequestState: ServiceRequestStateEnum | undefined;
  errorMessage: string | undefined;
}

export class ServiceRequestSnackUpdateCommand {
  idServiceRequestSnack: number | undefined;
  idSnackType: number | undefined;
  passengersCount: number | undefined;
}

export class ServiceRequestSnackVoucherSnackActivationListQueryResponse {
  items: VoucherSnackActivationSummary[] | undefined;
}

export class ServiceRequestState {
  idServiceRequestState: ServiceRequestStateEnum | undefined;
  name: string | undefined;
}

export enum ServiceRequestStateEnum {
  TO_BE_PROCESSED = 1,
  PROCESSING = 2,
  IN_PROGRESS = 3,
  COMPLETED = 4,
  ERROR = 5,
  CANCELED = 6,
  AWAITING_CANCELLATION = 7,
}

export class ServiceRequestTransportOrderResponse {
  hotelName: string | undefined;
  passengersCount: number | undefined;
  hotelPrefix: string | undefined;
  hotelPhoneNumber: string | undefined;
  hotelAddress: string | undefined;
  idTransportOrder: number | undefined;
  items: TransportOrderItem[] | undefined;
}

export class ServiceRequestType {
  idServiceRequestType: ServiceRequestTypeEnum | undefined;
  name: string | undefined;
}

export enum ServiceRequestTypeEnum {
  AIRPORT_TO_AIRPORT = 1,
  AIRPORT_TO_HOTEL = 2,
  HOTEL_TO_AIRPORT = 3,
  SNACK = 4,
}

export class ServiceRequestUpdateCommand {
  idTicket: number | undefined;
  idServiceRequest: number | undefined;
  notes: string | undefined;
}

export class ServiceSnackBoardingPassScanLogItem {
  pnr: string | undefined;
  flightNumber: string | undefined;
  idTicket: number | undefined;
  idServiceSnack: number | undefined;
  snackType: SnackType | undefined;
  scannedAt: moment.Moment | undefined;
}

export class SnackType {
  idSnackType: number | undefined;
  name: string | undefined;
  amount: number | undefined;
  backgroundColor: string | undefined;
  textColor: string | undefined;
  colorName: string | undefined;
}

export class SnackTypeListByIdCustomerResponse {
  items: SnackType[] | undefined;
}

export class StopoverAvailability {
  hotelsCount: number | undefined;
  availableRooms: number | undefined;
}

export class Supplier {
  idSupplier: number | undefined;
  name: string | undefined;
}

export class SupplierFoodServiceCompany {
  idSupplierFoodServiceCompany: number | undefined;
  idSupplier: number | undefined;
  idLanguageCulture: number | undefined;
  name: string | undefined;
  restaurantGroup: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  notes: string | undefined;
  fileNameLogo: string | undefined;
  disclaimerOrderForm: string | undefined;
}

export class SupplierFoodServiceCompanyCreateCommand {
  idSupplier: number | undefined;
  restaurantGroup: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  notes: string | undefined;
  fileNameLogo: string | undefined;
  disclaimerOrderForm: string | undefined;
}

export class SupplierFoodServiceCompanyListQueryResponse {
  items: SupplierFoodServiceCompany[] | undefined;
}

export class SupplierFoodServiceCompanyListUsersQueryResponse {
  items: User[] | undefined;
}

export class SupplierFoodServiceCompanyReadResponse {
  idSupplierFoodServiceCompany: number | undefined;
  idSupplier: number | undefined;
  idLanguageCulture: number | undefined;
  name: string | undefined;
  restaurantGroup: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  notes: string | undefined;
  fileNameLogo: string | undefined;
  disclaimerOrderForm: string | undefined;
}

export class SupplierFoodServiceCompanyUpdateCommand {
  idSupplierFoodServiceCompany: number | undefined;
  idSupplier: number | undefined;
  name: string | undefined;
  restaurantGroup: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  notes: string | undefined;
  fileNameLogo: string | undefined;
  disclaimerOrderForm: string | undefined;
}

export class SupplierFoodServiceCompanyUserEmitVoucherSnackCommand {
  boardingPass: string | undefined;
}

export class SupplierFoodServiceCompanyUserListVoucherSnackByBoardingPassResponse {
  pnr: string | undefined;
  flightNumber: string | undefined;
  airlineCompanyIata: string | undefined;
  dayOfTheYear: number | undefined;
  toEmit: ServiceSnackBoardingPassScanLogItem[] | undefined;
  emitted: ServiceSnackBoardingPassScanLogItem[] | undefined;
}

export class SupplierFoodServiceCompanyVoucherSnackBoardingPassScannedListResponse {
  items: ServiceSnackBoardingPassScanLogItem[] | undefined;
}

export class SupplierListQueryResponse {
  items: Supplier[] | undefined;
}

export class SupplierTransportCompany {
  idSupplierTransportCompany: number | undefined;
  idLanguageCulture: number | undefined;
  name: string | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  owner: string | undefined;
  notes: string | undefined;
}

export class SupplierTransportCompanyContact {
  idSupplierTransportCompanyContact: number | undefined;
  idSupplierTransportCompany: number | undefined;
  idLanguageCulture: number | undefined;
  name: string | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  isActive: boolean | undefined;
  languageCulture: LanguageCulture | undefined;
}

export class SupplierTransportCompanyContactCreateCommand {
  idSupplierTransportCompany: number | undefined;
  name: string | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  isActive: boolean | undefined;
  idLanguageCulture: number | undefined;
}

export class SupplierTransportCompanyContactListQueryResponse {
  items: SupplierTransportCompanyContact[] | undefined;
}

export class SupplierTransportCompanyContactUpdateCommand {
  idSupplierTransportCompanyContact: number | undefined;
  name: string | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  isActive: boolean | undefined;
  idLanguageCulture: number | undefined;
}

export class SupplierTransportCompanyCreateCommand {
  idSupplier: number | undefined;
  idLanguageCulture: number | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  owner: string | undefined;
  notes: string | undefined;
}

export class SupplierTransportCompanyListQueryResponse {
  items: SupplierTransportCompany[] | undefined;
}

export class SupplierTransportCompanyListUsersQueryResponse {
  items: User[] | undefined;
}

export class SupplierTransportCompanyReadResponse {
  idSupplierTransportCompany: number | undefined;
  idSupplier: number | undefined;
  idLanguageCulture: number | undefined;
  name: string | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  owner: string | undefined;
  notes: string | undefined;
}

export class SupplierTransportCompanyUpdateCommand {
  idSupplierTransportCompany: number | undefined;
  idSupplier: number | undefined;
  idLanguageCulture: number | undefined;
  name: string | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  owner: string | undefined;
  notes: string | undefined;
}

export class Ticket {
  idTicket: number | undefined;
  idAirlineCompany: number | undefined;
  idAirportOpeningTicket: number | undefined;
  idCustomer: number | undefined;
  idCustomerAirlineCompany: number | undefined;
  iataCodeAirportOpeningTicket: string | undefined;
  idPassengerRegistrationType: PassengerRegistrationTypeEnum | undefined;
  idVoucherSnackManagementType: VoucherSnackManagementTypeEnum | undefined;
  customerName: string | undefined;
  flightNumber: string | undefined;
  passengersCount: number | undefined;
  openingDate: moment.Moment | undefined;
  transportClosedBy: string | undefined;
  clientSideClosedBy: string | undefined;
  hotelClosedBy: string | undefined;
  transportClosedAt: moment.Moment | undefined;
  clientSideClosedAt: moment.Moment | undefined;
  hotelClosedAt: moment.Moment | undefined;
  deletedAt: moment.Moment | undefined;
  proformaInvoicePublishedAt: moment.Moment | undefined;
  proformaInvoicePublishedBy: number | undefined;
  notes: string | undefined;
  prm: boolean | undefined;
  crew: boolean | undefined;
  idCreationUser: number | undefined;
  registredPassengersCount: number | undefined;
  username: string | undefined;
  operatorName: string | undefined;
  operatorSurname: string | undefined;
  idUserType: UserTypeEnum | undefined;
  rowNumber: number | undefined;
  workedRowNumber: number | undefined;
  processingStatus: boolean | undefined;
  hasServiceAirportToAirport: boolean | undefined;
  hasServiceAirportToHotel: boolean | undefined;
  hasServiceHotelToAirport: boolean | undefined;
  hasServiceSnack: boolean | undefined;
  hasAttachments: boolean | undefined;
  airportOpeningTicket: Airport | undefined;
  airlineCompany: AirlineCompany | undefined;
  customerAirlineCompany: CustomerAirlineCompany | undefined;
}

export class TicketCreateCommand {
  idAirportOpeningTicket: number | undefined;
  idAirportDeparture: number | undefined;
  idAirportDestination: number | undefined;
  idPassengerRegistrationType: PassengerRegistrationTypeEnum | undefined;
  idAirlineCompany: number | undefined;
  idCustomerAirlineCompany: number | undefined;
  prm: boolean | undefined;
  crew: boolean | undefined;
  flightNumber: string | undefined;
  passengersCount: number | undefined;
  notes: string | undefined;
}

export class TicketFile {
  idTicketFile: number | undefined;
  idTicket: number | undefined;
  fileName: string | undefined;
  contentType: string | undefined;
  fileUrl: string | undefined;
}

export class TicketFileListByIdTicketQueryResponse {
  items: TicketFile[] | undefined;
}

export class TicketListQuery {
  idAirlineCompany: number | undefined;
  flightNumber: string | undefined;
  openingDate: moment.Moment | undefined;
}

export class TicketListQueryResponse {
  items: Ticket[] | undefined;
}

export class TicketReadHotelClosedAtByIdServiceAirportToHotelQueryResponse {
  hotelClosedAt: moment.Moment | undefined;
}

export class TicketUpdateCommand {
  idTicket: number | undefined;
  idAirportOpeningTicket: number | undefined;
  idPassengerRegistrationType: PassengerRegistrationTypeEnum | undefined;
  idAirlineCompany: number | undefined;
  prm: boolean | undefined;
  crew: boolean | undefined;
  flightNumber: string | undefined;
  passengersCount: number | undefined;
  notes: string | undefined;
}

export class TicketUpdateNoteCommand {
  idTicket: number | undefined;
  notes: string | undefined;
}

export class TransportOrderItem {
  idTransportOrderItem: number | undefined;
  idSupplierTransportCompany: number | undefined;
  idTransportOrder: number | undefined;
  idCurrency: number | undefined;
  driverName: string | undefined;
  driverPrefix: string | undefined;
  driverPhoneNumber: string | undefined;
  hotelAddress: string | undefined;
  hotelPrefix: string | undefined;
  hotelPhoneNumber: string | undefined;
  notes: string | undefined;
  buyRouteAmount: number | undefined;
  buyExtraAmount: number | undefined;
  buyNocturneOrFestiveAmount: number | undefined;
  buyZtlAmount: number | undefined;
  buyDriverMealAmount: number | undefined;
  buyOvernightDriverAmount: number | undefined;
  buyExtraDriverAmount: number | undefined;
  buyTotalAmount: number | undefined;
  buyExtraNotes: string | undefined;
  amountNotes: string | undefined;
  arrivalRequestedPickupDate: moment.Moment | undefined;
  capacity: number | undefined;
}

export class TransportOrderItemAmountSaveCommand {
  idTransportOrderItem: number | undefined;
  buyRouteAmount: number | undefined;
  buyExtraAmount: number | undefined;
  buyNocturneOrFestiveAmount: number | undefined;
  buyZtlAmount: number | undefined;
  buyDriverMealAmount: number | undefined;
  buyOvernightDriverAmount: number | undefined;
  buyExtraDriverAmount: number | undefined;
  buyExtraNotes: string | undefined;
  amountNotes: string | undefined;
}

export class TransportOrderItemCreateCommand {
  idTransportOrder: number | undefined;
  idCurrency: number | undefined;
  driverName: string | undefined;
  driverPrefix: string | undefined;
  driverPhoneNumber: string | undefined;
  capacity: number | undefined;
  arrivalRequestedPickupDate: moment.Moment | undefined;
  notes: string | undefined;
}

export class TransportOrderItemListByIdTransportOrderQueryResponse {
  items: TransportOrderItem[] | undefined;
}

export class TransportOrderItemSvcResponse {
  idTransportOrderItem: number | undefined;
  idTransportCompany: number | undefined;
  idTransportOrder: number | undefined;
  driverName: string | undefined;
  driverPrefix: string | undefined;
  driverPhoneNumber: string | undefined;
  arrivalRequestedPickupDate: moment.Moment | undefined;
  capacity: number | undefined;
}

export class TransportOrderItemUpdateCommand {
  idTransportOrderItem: number | undefined;
  driverName: string | undefined;
  driverPrefix: string | undefined;
  driverPhoneNumber: string | undefined;
  capacity: number | undefined;
  arrivalRequestedPickupDate: moment.Moment | undefined;
  notes: string | undefined;
}

export class TransportOrderListCanceledQueryResponse {
  items: TransportOrderListItem[] | undefined;
}

export class TransportOrderListClosedQueryResponse {
  items: TransportOrderListItem[] | undefined;
}

export class TransportOrderListDeletedQueryResponse {
  items: TransportOrderListItem[] | undefined;
}

export class TransportOrderListInProgressQueryResponse {
  items: TransportOrderListItem[] | undefined;
}

export class TransportOrderListItem {
  idServiceRequest: number | undefined;
  idTicket: number | undefined;
  idTransportOrder: number | undefined;
  idServiceRequestType: ServiceRequestTypeEnum | undefined;
  departure: string | undefined;
  departureAddress: string | undefined;
  destination: string | undefined;
  destinationAddress: string | undefined;
  airlineCompanyIataCode: string | undefined;
  flightNumber: string | undefined;
  passengersCount: number | undefined;
  notes: string | undefined;
  requestedPickupDate: moment.Moment | undefined;
  replacementFlightDate: moment.Moment | undefined;
  hasTransportArrivalDate: boolean | undefined;
  hasDriverPhoneNumeber: boolean | undefined;
  vehicles: number | undefined;
  idInvoiceTransportOrder: number | undefined;
  invoiceNumber: string | undefined;
  buyAmount: number | undefined;
  buyDriverAmount: number | undefined;
  canceled: boolean | undefined;
  deleted: boolean | undefined;
  closedDate: moment.Moment | undefined;
  idServiceRequestCancellationDebitBehavior: ServiceRequestCancellationDebitBehaviorEnum | undefined;
  capacity: number | undefined;
  createdAt: moment.Moment | undefined;
}

export class TransportOrderListToInvoiceQueryResponse {
  items: TransportOrderListItem[] | undefined;
}

export class TransportOrderStatusType {
  idTransportOrderStatusType: TransportOrderStatusTypeEnum | undefined;
  name: string | undefined;
}

export enum TransportOrderStatusTypeEnum {
  TO_CONFIRM = 1,
  PROCESSING = 2,
  CANCELED = 3,
  DELETED = 4,
  BILLABLE = 5,
}

export class TransportOrderSvcResponse {
  idTransportOrder: number | undefined;
  idTicket: number | undefined;
  idTransportCompany: number | undefined;
  passengersCount: number | undefined;
  requestedPickupDate: moment.Moment | undefined;
  isZtl: boolean | undefined;
  transportOrderItems: TransportOrderItemSvcResponse[] | undefined;
}

export class TransportOrderUpdateCommand {
  idTicket: number | undefined;
  idServiceRequest: number | undefined;
  notes: string | undefined;
}

export class User {
  idUser: number | undefined;
  idUserState: UserStateEnum | undefined;
  idUserType: UserTypeEnum | undefined;
  idLanguageCulture: number | undefined;
  name: string | undefined;
  surname: string | undefined;
  email: string | undefined;
  username: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  userRole: string | undefined;
  lockedDate: moment.Moment | undefined;
  activationDate: moment.Moment | undefined;
}

export class UserCreateCommand {
  idUserState: UserStateEnum | undefined;
  idUserType: UserTypeEnum | undefined;
  idLanguageCulture: number | undefined;
  name: string | undefined;
  surname: string | undefined;
  username: string | undefined;
  password: string | undefined;
  idCustomerAirlineCompany: number | undefined;
  idSupplierTransportCompany: number | undefined;
  idSupplierFoodServiceCompany: number | undefined;
}

export class UserGenerateResetPasswordCodeCommand {
  username: string | undefined;
  scheme: string | undefined;
  host: string | undefined;
}

export class UserListByEntityQueryResponse {
  items: UserReadQueryResponse[] | undefined;
}

export class UserListQuery {
  idsUserType: UserTypeEnum[] | undefined;
}

export class UserListQueryResponse {
  items: UserReadQueryResponse[] | undefined;
}

export class UserReadByUniqueCodeQueryResponse {
  uniqueCode: string | undefined;
  activationDate: moment.Moment | undefined;
  activationCode: string | undefined;
  resetPasswordCode: string | undefined;
}

export class UserReadByUsernameQueryResponse {
  uniqueCode: string | undefined;
  resetPasswordCode: string | undefined;
  activationDate: moment.Moment | undefined;
}

export class UserReadQueryResponse {
  idUser: number | undefined;
  idUserState: UserStateEnum | undefined;
  idUserType: UserTypeEnum | undefined;
  idLanguageCulture: number | undefined;
  name: string | undefined;
  surname: string | undefined;
  email: string | undefined;
  activationCode: string | undefined;
  username: string | undefined;
  password: string | undefined;
  salt: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  userRole: string | undefined;
  lockedDate: moment.Moment | undefined;
  activationDate: moment.Moment | undefined;
}

export class UserReadUserInfoQueryResponse {
  username: string | undefined;
  name: string | undefined;
  surname: string | undefined;
  idTipoUtente: UserTypeEnum | undefined;
  tipoUtente: string | undefined;
  nomeLavoro: string | undefined;
  nomeAeroporto: string | undefined;
  lingua: LanguageCulture | undefined;
}

export class UserResetPasswordCommand {
  uniqueCode: string | undefined;
  resetPasswordCode: string | undefined;
  password: string | undefined;
  confirmPassword: string | undefined;
}

export class UserState {
  idUserState: UserStateEnum | undefined;
  name: string | undefined;
}

export enum UserStateEnum {
  ACTIVE = 1,
  INACTIVE = 2,
  CANCELED = 3,
}

export class UserType {
  idUserType: UserTypeEnum | undefined;
  name: string | undefined;
  description: string | undefined;
}

export enum UserTypeEnum {
  SUPER_ADMIN = 1,
  CUSTOMER_AIRLINE_COMPANY_ADMIN = 2,
  CUSTOMER_AIRLINE_COMPANY_USER = 3,
  SUPPLIER_HOTEL_ADMIN = 4,
  SUPPLIER_TRANSPORT_ADMIN = 5,
  PASSENGER = 6,
  SUPPLIER_FOOD_SERVICE_COMPANY_ADMIN = 7,
  SUPPLIER_FOOD_SERVICE_COMPANY_USER = 8,
  SUPPLIER_HOTEL_USER = 9,
  SUPPLIER_HOTEL_HOTAC = 10,
  OPERATOR = 11,
}

export class UserUpdateCommand {
  idUser: number | undefined;
  idUserType: UserTypeEnum | undefined;
  name: string | undefined;
  surname: string | undefined;
  username: string | undefined;
  idCustomerAirlineCompany: number | undefined;
  idSupplierTransportCompany: number | undefined;
  idSupplierFoodServiceCompany: number | undefined;
}

export class UserVerifyResetPasswordCodeCommand {
  uniqueCode: string | undefined;
  resetPasswordCode: string | undefined;
}

export class VoucherSnack {
  idSnackType: number | undefined;
  name: string | undefined;
  amount: number | undefined;
  backgroundColor: string | undefined;
  textColor: string | undefined;
  totalAmountVoucherSnack: number | undefined;
  totalVoucherSnack: number | undefined;
}

export class VoucherSnackActivateCommand {
  idServiceRequestSnack: number | undefined;
  vouchers: string[] | undefined;
}

export class VoucherSnackActivateCommandResponse {
  activationErrors: ActivationError[] | undefined;
}

export class VoucherSnackActivateWithBoardingPassCommand {
  boardinPass: string | undefined;
  idServiceRequestSnack: number | undefined;
  vouchers: string[] | undefined;
}

export class VoucherSnackActivateWithBoardingPassCommandResponse {
  activationErrors: ActivationError[] | undefined;
}

export class VoucherSnackActivationSummary {
  idVoucherSnackActivation: number | undefined;
  idServiceSnackBoardingPassScan: number | undefined;
  idServiceSnack: number | undefined;
  passengerName: string | undefined;
  passengerSurname: string | undefined;
  passengerPnr: string | undefined;
  snackTypeName: string | undefined;
  snackAmount: number | undefined;
  backgroundColor: string | undefined;
  textColor: string | undefined;
}

export class VoucherSnackCreateCommand {
  idCustomer: number | undefined;
  idSnackType: number | undefined;
  quantity: number | undefined;
}

export class VoucherSnackListByIdCustomerResponse {
  items: VoucherSnackResponse[] | undefined;
}

export class VoucherSnackManagementType {
  idVoucherSnackManagementType: VoucherSnackManagementTypeEnum | undefined;
  name: string | undefined;
}

export enum VoucherSnackManagementTypeEnum {
  VOUCHER = 1,
  VOUCHER_AND_BOARDING_PASS = 2,
  BOARDING_PASS = 3,
}

export class VoucherSnackResponse {
  idVoucherSnackRequestEmission: number | undefined;
  idSnackType: number | undefined;
  idAirlineCompany: number | undefined;
  customerName: string | undefined;
  name: string | undefined;
  quantity: number | undefined;
  printUrl: string | undefined;
  value: string | undefined;
  idUserCreatedBy: number | undefined;
}

export class Hotac_Create {
  file: File | undefined;
  id: number | undefined;
}

export class Hotac_UploadRoomList {
  file: File | undefined;
  id: number | undefined;
}

export class Hotac_UploadInvoice {
  file: File | undefined;
  id: number | undefined;
}

export class ServiceAirportToHotelHasPassengerGroup_UploadRoomListInService {
  file: File | undefined;
  id: number | undefined;
}

export class TicketFile_Create {
  file: File | undefined;
  id: number | undefined;
}


